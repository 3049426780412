export const serviceCardList = [
	{
		title: 'servicePage.serviceCard.first.title',
		description: 'servicePage.serviceCard.first.description',
	},
	{
		title: 'servicePage.serviceCard.second.title',
		description: 'servicePage.serviceCard.second.description',
	}
];
