const Colors = {
	purpleLight: '#A39EF8',
	purple: '#3F25F2',
	purpleDark: '#160AB4',
	purpleLighter: '#EFEEFF',
	defaultText: '#2D2B30',
	white: '#FFFFFF',
	black: '#000000',
};
export default Colors;
